<script setup>
import AppTable from '@components/AppTable.vue'
import { computed, ref } from 'vue'
import { defaultTableParams } from '@utils/app-table'
import request from '@utils/request'
import { useI18n } from 'vue-i18n-bridge'
import AppInput from '@components/AppInput.vue'
import AppPreviewFile from '@components/AppPreviewFile.vue'
import {
  swalConfirmDelete,
  swalRequestErrorHandler,
  swalSuccess
} from '@utils/swal'
import { getFile, objFormData } from '@utils/function'
import AppSingleFile from '@components/AppSingleFile.vue'
const { t } = useI18n()
const props = defineProps({
  mode: {
    type: String,
    required: true
  },
  view: {
    type: Boolean,
    required: true
  },
  userId: {
    type: String,
    required: true
  }
})

const tableHeaders = computed(() => [
  {
    text: t('fields.package'),
    value: 'package',
    items: packageItems.value
  },
  {
    text: t('fields.gb'),
    value: 'gb'
  },
  {
    text: t('fields.start'),
    value: 'start_date'
  },
  {
    text: t('fields.end'),
    value: 'end_date'
  },
  {
    text: t('fields.note'),
    value: 'note'
  },
  {
    text: t('fields.document'),
    value: 'document',
    sortable: false,
    hideFilter: true
  },
  {
    text: t('label.action'),
    value: 'actions',
    sortable: false,
    hideFilter: true
  }
])

const loading = ref(false)
const dialogVisible = ref(false)
const dialogPreview = ref(false)
const selected = ref([])
const count = ref(0)
const items = ref([])

const editingItem = ref({})
const packageItems = computed(() => [
  {
    label: 'Subscription',
    value: 'subscription'
  },
  {
    label: 'Storage',
    value: 'storage'
  }
])
const newDocument = ref(null)

const getItems = async (options) => {
  loading.value = true
  try {
    let params = defaultTableParams(options)
    const { data } = await request({
      method: 'get',
      url: `auth/usersubscription/?user_id=${props.userId}`,
      params: params
    })
    items.value = data.results
    count.value = data.count
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

const onEdit = (item) => {
  editingItem.value = Object.assign({}, item)
  dialogVisible.value = true
}
const onClose = () => {
  dialogVisible.value = false
  editingItem.value = {}
  loading.value = false
}
const onDelete = async (items) => {
  const result = await swalConfirmDelete(t)
  if (result.isConfirmed) {
    try {
      loading.value = true

      await request({
        method: 'delete',
        url: `auth/usersubscription/`,
        data: {
          pks: items.map((value) => value.id)
        }
      })
      selected.value = []
      swalSuccess(t)
      getItems()
    } catch (response) {
      swalRequestErrorHandler(t, response)
      console.error(response)
    } finally {
      loading.value = false
    }
  }
}

const onSave = async () => {
  try {
    loading.value = true
    const data = {
      user: props.userId,
      package: editingItem.value.package,
      gb: editingItem.value.gb,
      start_date: editingItem.value.start_date,
      end_date: editingItem.value.end_date,
      note: editingItem.value.note,
      id: editingItem.value.id,
      document: newDocument.value
    }

    if (editingItem.value.id) {
      await request({
        method: 'put',
        url: `auth/usersubscription/${editingItem.value.id}/`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: objFormData(data)
      })
    } else {
      await request({
        method: 'post',
        url: `auth/usersubscription/`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: objFormData(data)
      })
    }
    newDocument.value = null
    swalSuccess(t)
    onClose()
    await getItems()
  } catch (response) {
    swalRequestErrorHandler(t, response)
    console.error(response)
  } finally {
    loading.value = false
  }
}

const previewFile = ref(null)
const previewReady = ref(false)
const onPreview = async (item) => {
  dialogPreview.value = true
  previewReady.value = false
  previewFile.value = await getFile(item.document)
  previewReady.value = true
}
</script>

<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Subscriptions</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="props.mode === 'edit'"
          @click="onEdit()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          v-show="selected.length && props.mode === 'edit'"
          color="error"
          @click="onDelete(selected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          app="authentication"
          model="usersubscription"
          :headers="tableHeaders"
          :hide-edit="props.mode !== 'edit'"
          :hide-delete="props.mode !== 'edit'"
          v-model="selected"
          :server-items="items"
          :server-items-length="count"
          @server="getItems"
          @edit="onEdit"
          @delete="onDelete"
        >
          <template v-slot:[`item.document`]="{ item }">
            <v-btn v-if="item.document" @click="onPreview(item)" text>
              <v-icon color="primary">mdi-file</v-icon>
            </v-btn>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogVisible" max-width="700px" persistent>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <app-input
                  name="package"
                  type="select"
                  :label="$t('fields.package')"
                  v-model="editingItem.package"
                  :view="view"
                  :items="packageItems"
                />
              </v-col>
              <v-col cols="6">
                <app-input
                  name="gb"
                  type="number"
                  :label="$t('fields.gb')"
                  v-model="editingItem.gb"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <app-input
                  name="start_date"
                  type="datepicker"
                  :label="$t('fields.start')"
                  v-model="editingItem.start_date"
                  :view="view"
                />
              </v-col>
              <v-col cols="6">
                <app-input
                  name="end_date"
                  type="datepicker"
                  :label="$t('fields.end')"
                  v-model="editingItem.end_date"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editingItem.note"
                  :view="view"
                />
              </v-col>
              <v-col cols="6">
                <app-input
                  name="document"
                  type="file"
                  :label="$t('fields.document')"
                  v-model="newDocument"
                  :view="view"
                />
                <app-single-file
                  v-if="editingItem.document"
                  :file-url="editingItem.document"
                  :view="view"
                  :mode="mode"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreview" max-width="700px">
      <app-preview-file :file="previewFile" :file-ready="previewReady" />
    </v-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
