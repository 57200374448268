<template>
  <v-sheet color="grey lighten-4" class="pa-3">
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      height="300"
      type="image"
      :loading="!fileReady"
    >
      <v-img
        v-if="previewFile && isImage(file)"
        :src="previewFile.src"
        class="app-preview-file"
        width="100%"
        height="100%"
      />
      <embed
        v-if="isPdf(file)"
        :src="previewFile.src"
        width="100%"
        height="100%"
      />
    </v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  name: 'app-preview-file',
  props: {
    /**
     * files to preview
     */
    file: {
      type: File,
      required: true
    },
    /**
     * file is ready
     */
    fileReady: {
      type: Boolean,
      default: false
    },
    /**
     * bind options for v-progress-linear
     */
    binds: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      previewFile: null
    }
  },
  watch: {
    file(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setPreview()
      }
    }
  },
  methods: {
    isImage(file) {
      return ['image/gif', 'image/jpeg', 'image/png'].includes(file.type)
    },
    isPdf(file) {
      return file.type === 'application/pdf'
    },
    async setPreview() {
      if (this.fileReady) {
        if (this.isImage(this.file) || this.isPdf(this.file)) {
          this.previewFile = {
            type: this.isPdf(this.file) ? 'pdf' : 'image',
            file: this.file,
            src: URL.createObjectURL(this.file)
          }
        }
      } else {
        this.previewFile = null
      }
    }
  }
}
</script>

<style lang="scss">
.app-preview-file {
  border: 5px solid #616161;
}
</style>
