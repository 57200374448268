<template>
  <app-form-view
    multipart
    app="authentication"
    model="user"
    api-url="auth/user/"
    :title="$t('menu.user')"
    :state-items="stateItems"
    :list-route="{ name: 'user' }"
    :prepare-data="prepareData"
    v-model="formData"
    :approveItems="approveItems"
  >
    <template v-slot="{ view, mode }">
      <v-dialog v-model="dialogResetPassword" max-width="800px">
        <v-card flat>
          <v-card-text class="pt-4">
            <v-toolbar dense color="primary white--text">
              <v-toolbar-title>
                {{ $t('label.resetPassword') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-row class="mt-4">
              <v-col cols="12">
                <app-input
                  name="password"
                  type="password"
                  :label="$t('fields.password')"
                  v-model="resetPassword.password"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <app-input
                  name="confirmPassword"
                  type="password"
                  :label="$t('fields.confirmPassword')"
                  v-model="resetPassword.confirmPassword"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeResetPassword">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="confirmResetPassword">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="12" class="d-flex">
          <app-input
            name="profile"
            type="picture"
            :label="$t('fields.profile')"
            :view="view"
            :binds="{
              width: '200',
              height: '200'
            }"
            :view-binds="{
              width: '200',
              height: '200'
            }"
            v-model="formData.image"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-input
            name="userType"
            type="radio"
            :label="$t('label.userType')"
            :view="view"
            :items="[
              { label: $t('label.internal'), value: 'internal' },
              { label: $t('label.external'), value: 'external' }
            ]"
            v-model="formData.user_type"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="email"
            rules="required|email"
            :label="$t('fields.email')"
            :view="view"
            v-model="formData.email"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEmail"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_email"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="username"
            rules="required"
            :label="$t('fields.username')"
            :view="view"
            :disabled="mode !== 'create'"
            v-model="formData.username"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveUserName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_username"
          />
        </v-col>
      </v-row>
      <v-row v-if="mode === 'create'">
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="password"
            rules="required"
            type="password"
            :label="$t('fields.password')"
            v-model="formData.password"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePassword"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_password"
          />
        </v-col>
        <v-col cols="12" md="6">
          <app-input
            name="confirmPassword"
            rules="required|confirmed:password"
            type="password"
            :label="$t('fields.confirmPassword')"
            v-model="formData.confirm_password"
          >
          </app-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="contact"
            type="select-server"
            :label="$t('fields.contact')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?contact_type=individual&active=true'
            }"
            :filterObject="filterObject"
            v-model="formData.contact_id"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveContact"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="accessRight"
            type="select-server"
            rules="required"
            :label="$t('fields.accessRight')"
            :view="view"
            :binds="{
              apiUrl: 'auth/group/?is_displayed=True'
            }"
            v-model="formData.group_id"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveaccessRight"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_access_right"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="startDate"
            type="datepicker"
            :label="$t('fields.startDate')"
            :view="view"
            v-model="formData.start_date"
          />

          <app-input
            v-if="isSentToApprove"
            name="approveStartDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_start_date"
          />
        </v-col>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="endDate"
            type="datepicker"
            :label="$t('fields.endDate')"
            :view="view"
            v-model="formData.end_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEndDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_end_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <template v-if="formData.customer_company_id !== undefined">
            <app-input
              name="customer_name"
              type="select"
              rules="required"
              :label="$t('fields.customerCompany')"
              :items="companyItems"
              :view="view"
              :binds="{
                loading: companyLoading,
                itemText: 'name',
                itemValue: 'id',
                returnObject: true
              }"
              v-model="formData.customer_company_id"
            />
            <app-input
              v-if="isSentToApprove"
              name="approveCustomerCompany"
              type="switch"
              class="approve"
              :label="$t('fields.approve')"
              v-model="formData.approve_customer_company"
            />
          </template>
        </v-col>
        <v-col cols="12" md="6" v-if="mode === 'edit'">
          <v-btn color="blue darken-1" text @click="openResetPassword">
            Reset Password
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="app-input--approve-wrapper">
          <app-input
            name="description"
            type="textarea"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDesciption"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_desciption"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.is_active"
          />
        </v-col>
        <v-col cols="12" md="6" v-if="formData.activate !== undefined">
          <app-input
            name="activate"
            type="switch"
            :label="$t('fields.activate')"
            :view="view"
            v-model="formData.activate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-if="formData.always_allow_login !== undefined"
        >
          <app-input
            name="always_allow_login"
            type="switch"
            :label="$t('fields.alwaysAllowLogin')"
            :view="view"
            v-model="formData.always_allow_login"
          />
        </v-col>
      </v-row>

      <v-expansion-panels :value="0">
        <app-expansion-panel :headerText="$t('menu.subscription')">
          <app-user-subscription
            :mode="mode"
            :view="view"
            :user-id="$route.params.id"
          />
        </app-expansion-panel>
      </v-expansion-panels>
    </template>
  </app-form-view>
</template>

<script>
import AppExpansionPanel from '@components/AppExpansionPanel.vue'
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppUserSubscription from '@components/view/AppUserSubscription.vue'

export default {
  name: 'user-form',
  components: {
    AppUserSubscription,
    AppExpansionPanel,
    AppFormView,
    AppInput
  },
  data() {
    return {
      formData: {
        state: 'draft',
        user_type: 'internal',
        customer_company_id: null
      },
      resetPassword: {
        password: null,
        confirmPassword: null
      },
      approveItems: [
        'approve_email',
        'approve_username',
        'approve_contact',
        'approve_access_right',
        'approve_start_date',
        'approve_end_date',
        'approve_desciption'
      ],
      activePicker: null,
      date: null,
      menu: false,
      isShow: true,
      dialogResetPassword: false,
      companyItems: [],
      companyLoading: false
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'userCreate'
    })
    this.setCompanyItems()
  },
  watch: {
    'formData.username': function (value) {
      if (this.$route.name === 'userEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'userEdit',
          text: value,
          to: {
            name: 'userEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    },
    companyItems() {
      this.$store.dispatch('refreshToken').then(() => {
        this.getUserCompany()
      })
    }
  },
  methods: {
    prepareData(data) {
      data.contact_id = data.contact_id?.id
      data.group_id = data.group_id.id
      data.customer_company_id = data.customer_company_id?.id
      return data
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.national_id_no
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    getUserCompany() {
      const company_id = this.$store.getters.user?.customer_company_id_id
      if (company_id) {
        this.formData.customer_company_id = company_id
      }
    },
    async setCompanyItems() {
      this.companyLoading = true
      await this.$api({
        method: 'get',
        url: 'customer-company/customer_company/?active=true',
        hideAlert: true
      })
        .then(({ data }) => {
          this.companyItems = data.results
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.companyLoading = false
        })
    },
    openResetPassword() {
      this.dialogResetPassword = true
    },
    closeResetPassword() {
      this.dialogResetPassword = false
      this.resetPassword = {}
    },
    async confirmResetPassword() {
      this.loading = true
      const data = {
        password: this.resetPassword.password,
        confirm_password: this.resetPassword.confirmPassword
      }
      await this.$api({
        method: 'put',
        url: `auth/user/resetpassword/${this.$route.params.id}/change_password/`,
        data: data
      }).finally(() => {
        this.loading = false
        this.dialogResetPassword = false
        this.resetPassword = {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  &--content {
    position: relative;
  }
}
</style>
